<app-antek-logo></app-antek-logo>

<app-tabs>
	<app-tab [active]="router.url.includes(getRoutesEnum.NETWORK_INFO)"
		(selectTabEvent)="onNetworkTabRouting(getRoutesEnum.NETWORK_INFO)"
		[location]="'/#/network;replaceUrl=true/(network-info:network-info)'">
		<app-tab-label>
			<img src="assets/feather-icons/info.svg" alt="info">
			<span>{{'NETWORK-SETTINGS.COMMON.info' | translate}}</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="network-info"></router-outlet>
		</app-tab-body>
	</app-tab>

	<app-tab *ngIf="haveAdminUserLevel$ | async"
		[active]="router.url.includes(getRoutesEnum.NETWORK_SETTINGS)"
		[tooltip]="(networkSettingsPermission$ | async)?.tooltip || ''"
		[disabled]="(networkSettingsPermission$ | async)?.isFeatureDisabled"
		[location]="'#/network;replaceUrl=true/(network-settings:network-settings;replaceUrl=true/(ports:ports))'"
		(selectTabEvent)="onNetworkTabRouting(getRoutesEnum.NETWORK_SETTINGS)">
		<app-tab-label>
			<img src="assets/feather-icons/settings.svg" alt="settings">
			<span>
				{{'NETWORK-SETTINGS.COMMON.settings' | translate}}
			</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="network-settings"></router-outlet>
		</app-tab-body>
	</app-tab>

	<!--
	<app-tab *ngIf="haveAdminUserLevel$ | async" [active]="router.url.includes(getRoutesEnum.NETWORK_ADMIN_FEATURES)"
	(selectTabEvent)="onNetworkTabRouting(getRoutesEnum.NETWORK_ADMIN_FEATURES)"
	[location]="'/#/network;replaceUrl=true/(network-admin-features:network-admin-features)'">
		<app-tab-label>
			<img src="assets/feather-icons/admin.svg" alt="settings">
			<span>
				{{'NETWORK-SETTINGS.TABS.adminSettings' | translate }}
			</span>
		</app-tab-label>

		<app-tab-body>
			<router-outlet name="network-admin-features"></router-outlet>
		</app-tab-body>
	</app-tab> -->
</app-tabs>
